import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from '@ecommerce/shared'
import Alert, { AlertModalSize, AlertProps } from '../Alert'

const cssPrefix = 'ConfirmationAlert__'

export enum ConfirmationAlertButtonsDirection {
  COLUMN = 'column',
  ROW = 'row',
}

const BodyWrapper = styled.div<{
  hasDescription: boolean
  absolutePositionCloseIcon: boolean
}>`
  width: 100%;
  .${cssPrefix} {
    padding-top: 23px;
    width: 100%;
    &text {
      margin-bottom: ${({ hasDescription }) => (hasDescription ? '7px' : '25px')};
      span {
        font-weight: bold;
        letter-spacing: -0.24px;
        font-size: ${({ hasDescription }) => (hasDescription ? '24px' : '17px')};
        ${({ hasDescription }) => hasDescription && 'line-height: 31px;'};
      }
    }
    &description {
      margin-bottom: 27px;
      span {
        font-weight: 325;
        letter-spacing: -0.24px;
        line-height: 25px;
        font-size: 17px;
        color: #5f6f86;
        ${({ hasDescription }) => hasDescription && 'line-height: 25px;'};
      }
    }
  }
`

const sharedButtonStyles = css<{ direction: ConfirmationAlertButtonsDirection }>`
  width: 100%;

  ${({ direction }) =>
    direction === ConfirmationAlertButtonsDirection.ROW &&
    css`
      min-width: 143px;
      margin-left: 21px;
    `}
`

const ButtonsWrapper = styled.div<{ direction: ConfirmationAlertButtonsDirection; switchButtons: boolean }>`
  width: 100%;

  ${({ direction }) =>
    direction === ConfirmationAlertButtonsDirection.ROW &&
    css`
      display: flex;
      justify-content: flex-end;
      padding-right: 32px;
    `};

  ${({ switchButtons }) =>
    switchButtons &&
    css`
      flex-direction: row-reverse;
    `};

  .${cssPrefix} {
    &confirm {
      ${sharedButtonStyles}
      margin-bottom: 8px;
    }
    &cancel {
      ${sharedButtonStyles}
    }
  }
`

export interface ConfirmationAlertProps extends AlertProps {
  text?: string | React.ReactNode
  description?: string | React.ReactNode
  confirmButtonText: string
  cancelButtonText?: string
  header?: React.ReactNode
  footer?: React.ReactNode
  onClose?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onBlur?: () => void
  onConfirm?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onCancel?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
  buttonsDirection?: ConfirmationAlertButtonsDirection
  modalSize?: AlertModalSize
  absolutePositionCloseIcon?: boolean
  switchButtons?: boolean
}

const ConfirmationAlert = (props: ConfirmationAlertProps) => {
  const {
    onConfirm: onConfirmProp,
    onCancel: onCancelProp,
    text,
    description,
    confirmButtonText,
    cancelButtonText,
    className,
    buttonsDirection = ConfirmationAlertButtonsDirection.COLUMN,
    modalSize,
    absolutePositionCloseIcon = false,
    switchButtons = false,
    ...restProps
  } = props

  const onCancel = switchButtons ? onConfirmProp : onCancelProp
  const onConfirm = switchButtons ? onCancelProp : onConfirmProp

  const body = (
    <BodyWrapper
      className={className}
      hasDescription={!!description}
      absolutePositionCloseIcon={!!absolutePositionCloseIcon}
    >
      {text && <div className={`${cssPrefix}text`}>{typeof text === 'string' ? <span>{text}</span> : text}</div>}
      {description && (
        <div className={`${cssPrefix}description`}>
          {typeof description === 'string' ? <span>{description}</span> : description}
        </div>
      )}
      <ButtonsWrapper direction={buttonsDirection} switchButtons={!!switchButtons}>
        <Button
          className={`${cssPrefix}${switchButtons ? 'cancel' : 'confirm'}`}
          onClick={onConfirm}
          data-text="confirm-button"
        >
          {confirmButtonText}
        </Button>
        <Button
          data-test="cancel-button"
          btnType="secondary"
          className={`${cssPrefix}${switchButtons ? 'confirm' : 'cancel'}`}
          onClick={onCancel || props.onClose}
        >
          {cancelButtonText || 'Cancelar'}
        </Button>
      </ButtonsWrapper>
    </BodyWrapper>
  )
  return (
    <Alert
      {...restProps}
      body={body}
      className={className}
      modalSize={modalSize}
      absolutePositionCloseIcon={absolutePositionCloseIcon}
    />
  )
}

export default ConfirmationAlert
